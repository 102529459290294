import React, { useEffect } from "react";
import { postTracking, postWithoutAuthTracking } from "../../../api/Tracking";
import "../../Dashboard.scss";
import "./Energy.scss";
import EnergyWidgets from "./EnergyWidgets";
import EnergyDetailed from "./EnergyDetailed";

const Energy = ({ showDetailed, showAll, ...props }) => {
  useEffect(() => {
    const authData = localStorage.getItem("AuthData");
    const authToken = authData
      ? encodeURIComponent(authData)
      : localStorage.getItem("AuthToken")
      ? encodeURIComponent(localStorage.getItem("AuthToken"))
      : null;

    if (!authToken) {
      postWithoutAuthTracking("MAPS-HOME");
    } else {
      postTracking("MAPS-HOME");
    }
  }, []);

  const shouldDisplayDetailed = showAll && showDetailed;

  if (shouldDisplayDetailed)
    return <EnergyDetailed showAll={showAll} {...props} />;

  return <EnergyWidgets showAll={showAll} {...props} />;
};

export default Energy;
